
































import { Component, Vue, Watch } from 'vue-property-decorator'
import { QrcodeStream } from 'vue-qrcode-reader'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'
import BAutocomplete from '@/components/validation/BAutocomplete.vue'

@Component({
  components: {
    QrcodeStream,
    'AppForm': ValidationObserver,
    'AppInput': BInput,
    'AppAuto': BAutocomplete,
  }
})
export default class ScanSuratJalan extends Vue {
  //#region COMPUTED
  get loading() {
    return this.$store.getters.loading
  }
  get error() {
    return this.$store.getters.error
  }
  get existData() {
    return this.$store.getters['scan/suratJalan/existData']
  }
  //#endregion
  //#region DATA
  $refs!: { observer: InstanceType<typeof ValidationObserver> }
  barcode:any = null
  message:any = null
  camera:any = 'auto'
  //#endregion
  //#region WATCH
  //#endregion
  //#region METHODS
  async onDecode(val:any) {
    if (this.loading) return
    this.camera = 'off'
    this.barcode = val
    await this.$store.dispatch('scan/suratJalan/ScanData', { barcode: val })
  }
  reset() {
    this.camera = 'auto'
    this.barcode = null
    this.message = null
  }
  //#endregion
  //#region CREATED
  //#endregion
  //#region MOUNTED
  //#endregion
}
